.textBlue {
  color: #142033;
  font-size: 20px;
  fontWeight: bold
}
.textBlueSmall {
  color: #142033;
  font-size: 16px;
  fontWeight: bold
}
.textOrange {
  color: #FF9966;
  font-size: 20px;
  fontWeight: bold
}
.textRed {
  color: #FF6767;
  font-size: 16px;
  fontWeight: bold
}
.textCyan {
  color: #76CBC2;
  font-size: 20px;
  fontWeight: bold
}



.section-reinsurance{
  padding: 40px;
  display: none;
  @media screen and (min-width: $md) {
    display: block;
  }
  .reinsurance{
  }

  .reinsurance-box{
    min-width: 180px;
    width: calc( 25vw - 100px );
    /*max-width: 320px;*/
    .picto-box{

      &:first-child{

      }
    }
  }

}

