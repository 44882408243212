.page-order{

  .first-top-bg{
    min-height: 955px;
  }
  .section-order{
    position: relative;
    .order-info{
      @media screen and (min-width: $md) {
        transition: 0.5s;
        left: calc( 75% + 125px);
        transform: translateX( -50% );
        min-width: 228px;
        position: fixed;
        bottom: 90px;
        /*&.is-not-stcky{
          bottom: 20px;
          position: absolute;
        }*/

      }
      .cart,.order-address{
        position:relative;
        text-transform: uppercase;
        @media screen and (min-width: $md) {
          text-align: left;
        }
        h2{
          font-size: 35px;

          margin-bottom: 15px;
        }
        p{
          font-size: 18px;
          line-height: 32px;
          .highlight{
            color: #FF6767;
          }
        }
        text-align: center;
      }
    }

    form{
      max-width: 514px;
      margin: auto;
    }
    .MuiInputBase-root{
      background-color: transparent;
    }
    .MuiInputBase-input {
      box-shadow: none;
      border: solid 1px #BEBEBE;
      background-color: #F2F2F1;
      border-radius: 7px;
      overflow: hidden;
      width: 100%;
      &:focus{
        border-color: #76CBC2;
      }
    }
    .MuiFormLabel-root.Mui-focused{
      color: #76CBC2;
    }
    .MuiFilledInput-underline{
      &:before,&:after{
        display: none;
      }
    }
    button{
      width: calc( 100% - 40px);
      margin: 0px auto;
      position: relative;
      @media screen and (min-width: $md) {
        margin: 0px 20px;
      }
    }

    &.is-checkout .order-info{
      left: 25%;
      right: 25%;
      transform: translate(0px);
      /* text-align: center; */
      bottom: 100px;
      .cart,.order-address{
        text-align:center;
      }
    }
  }

}