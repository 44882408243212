.section-testimonial.primary-container{
    .secondary-container{
      padding: 50px 20px 90px 20px;
    }
  .custom-slick-arrow{
    background-color: transparent;
    span{
      background-color: #FFFFFF;
    }
  }
  .slick-dots li{
    button {
      background-color: transparent;
      width: 10px;
      height: 10px;
      border: solid 2px #FFFFFF;

      &:before {
        display: none;
      }
    }
    &.slick-active{
      button{
        border-color: #FF6767;
        background-color: #FF6767;
      }
    }
  }
  .slick-slide{
    div:first-child{
      margin: 0px auto 5px auto;
    }
  }
  background-color: #000033;
  .box{
    padding: 0px 35px;
    margin: 27px 0px 32px 0px;
    .content{
      color: #ffffff;
      .message{
        font-size: 20px;
        font-style: italic;
        .emoji{
          font-style: normal;
        }
      }
      .age{
        font-size: 14px;
      }
    }
  }
}