
.faq-component{
  .faq-columns{
    @media screen and (min-width: $md) {
      column-count: 2;
    }

    .box-container{
      break-inside:avoid;

    }
  }

  .box-container{
    @media screen and (min-width: $md) {
      overflow: hidden;
    }
    .box{
      font-size: 16px;
      color:#000000;
      width: 90%;
      @media screen and (min-width: $md) {
        overflow: hidden;
        margin: 16px 0px;
      }
      .issue{
        font-weight: bold;
        margin-bottom: 10px;
        color:#FF6767;
      }
      .answer{

      }
    }
  }
}