
.page-start{
  .page-content{
    padding-bottom: 100px;
  }

  .section-upload{
    height: 100%;
    position: absolute;
    width: 100%;
    .progress-bar-icon{
      top: 4px;
      position: relative;
      svg g rect,svg g path{
        fill: #76CBC2;
      }
    }
    .CircularProgressbar {
      width: 60px;
      .CircularProgressbar-trail{
        stroke: none;
      }
      .CircularProgressbar-path{
        stroke: #76CBC2;
        stroke-width: 10px;
      }
    }

    .secondary-container{
      height: 100%;
      padding-top: 20px;
    }
    .inputfile{
      visibility: hidden;
      height: 0.1px;
      font-size: 0px;
      position: absolute;
    }
    form{
      position: absolute;
      top: calc( 50% - 72px - 26px);
      left: 0px;
      width: 100%;
      text-align: center;
      button{
        display: inline-block;
      }
    }
    .background-container{
      width: 100%;
      img{
        position: absolute;
        z-index: 1;
      }
      div{
        top: 0px;
        position: absolute;
        height: 100%;
      }
      .background-left{
        width: 70%;
        left: 0px;
        background-color: #FBEBEB;
      }
      .background-right{
        width: 30%;
        right: 0px;
        background-color: #ffffff;
      }
    }

  }

  .section-tips{
   /* padding: 80px 20px 50px 20px;
    background-color: #ffffff;
    .background-container{
      left: 60%;
      transform: translateX(-47%);
    }*/
  }
  /*
  position: absolute;
  top: 50%;
         left: 50%;
                 background-color: transparent;
  border: none;
  color: #FF9966;
  opacity: 0.4;
  font-weight: bold;
  font-size: 20px;
  transform: translateX(-50%) translateY(-50%);*/
  /* max-width: 183px; */
  /*padding-right: 20px;  */

  .flip-book-conf{
    width: 290px;
    margin: 16px auto 16px auto;
    textarea{
      font-family: Lato;
    }
    .conf-container {
      position: relative;
      .conf-book {
        img{
          margin: 16px;
        }
      }
      .conf-title {
        border: none;
        font-size: 20px;
        color: #FF9966;
        background-color: transparent;
        position: absolute;
        top: calc( 50% - 5px);
        left: calc( 50% + 5px);
        transform: translateX(-50%) translateY(-50%);
        text-align: center;
        width: calc( 100% - 130px );
        font-family: lat;
        outline: none;
        resize: none;
        overflow: hidden;
        &::placeholder{
          color: #FF9966;
          opacity: 0.4;
        }
        &:placeholder-shown{
        }
        &:focus::placeholder {
        //  color: transparent;
        }
      }
      &:before{
       /* content: "";
        border-left-style: solid;
        border-left-width: 1px;
        animation-name: clignote;
        animation-iteration-count: infinite;
        animation-duration: 1s;
        position: absolute;
        top: calc( 50% - 16px );
        left: 94px;
        z-index: 100;
        height: 20px;*/
      }
    }
    &:focus-within{
      .conf-container:before{
        display: none;
      }
    }
  }

  .btn-select-video{
    display: block;
    //margin: calc( 50vh - 200px ) auto 0px auto;
    background-color: transparent;
    border-radius: 5px;
    border: solid 3px #000033;
    color: #000033;
    height: 305px;
    cursor: pointer;
    position: fixed;
    z-index: 9;
    left: calc( 50% - 150px );
    top: calc( 50% - 150px );
    @media screen and (min-width: $md) {
     // margin-top: calc( 40vh - 210px );
    }
  }
  .btn-create-flipbook{
    opacity: 0.4;
    pointer-events: none;
    min-width: 330px;
    &.is-actived{
      opacity: 1;
      pointer-events: initial;
    }
  }
  .progress-info {
    text-align: left;
    display: flex;
    max-width: 280px;
    margin: auto;
    align-items: center;
    justify-content: center;
    .circular-progress {
      display: inline-block;
    }

    .message-box {
      position: relative;
      /*height: 80px;*/
      width: 200px;
      margin: 0px auto 0px auto;
      padding-left: 5px;

      .progress-message {
        //position: absolute;
        //top: 0px;
        font-size: 20px;
        font-weight: bold;
        line-height: 25px;
        text-align: left;
        width: 100%;
        height: auto;
        margin: auto;
        .text-highlight {
          color: #76CBC2;
        }
      }
    }
  }
}


@keyframes clignote {
  0%{border-left-color:rgba(254,254,254,0.2);}
  50%{border-left-color:rgba(254,254,254,1);}
}
