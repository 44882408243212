.simple-content{
  position: relative;
  max-width: 604px;
  margin: auto;
  padding: 20px;
  counter-reset: foo 0;
  h1{
    font-size: 67px;
    font-weight: bold;
    text-align: center;
    line-height: 67px;
    margin: 16px;
    width: 100%;
    display: inline-block;
  }
  h2{
    font-size: 18px;
    text-align: left;
    font-weight: bold;
    margin-top: 55px;
  }
  p{
    &.sub-title{
      text-align: center;
      font-weight: bold;
      width: 75%;
      margin: auto;
    }
    font-size: 18px;
  }
  .description{
    margin-top: 50px;
  }


  .count-this:before {
    counter-increment: foo 1;
    content: counter(foo);
    display: inline-block;
    /* customize below */
    font-size: 1em;
    margin-right: 0.6em;
    width: 1.6em;
    line-height: 1.6em;
    text-align: center;
    border-radius: 50%;
    color: #FFF;
    background: #F77621;
  }

}

.description{
  .tips-component{
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

.page-faq{
  .simple-content{
    max-width: 1200px;
    .description{
      .faq-columns{
        @media screen and (min-width: $md) {
          column-count: 4;
        }
      }
    }
  }

}

.page-success{
  .description{
    text-align: center;
  }
  .button{
    margin-top: 100px;
  }
}