

.section-how-it-work{
  background-color: #ffffff;
  padding-bottom: 25px;
  height: 100%;
  .secondary-container{
    h2{
      font-size: 60px;
      line-height: 100%;
      font-weight: bold;
      text-align: left;
      @media screen and (min-width: $md) {
        margin: 0px;
      }
    }
    @media screen and (min-width: $md) {
      padding: 120px 120px 180px 120px;
    }
    .highlight-link-container{
      @media screen and (min-width: $md) {
        text-align: left;
        margin-top: 50px;
      }
    }
  }
}





.picto-box{
  text-align: center;
  font-weight: bold;
  line-height: 24px;
  font-sise: 18px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.07);
  padding: 16px;
  img{
    width: 100px; height: 100px; padding: 10px;
  }
}

.section-presentation{
  padding-bottom: 51px;
  &.primary-container{
    .secondary-container{
      @media screen and (min-width: $md) {
        padding: 0px 100px;
      }
      p{
        text-align: center;
        font-size: 15px;
        @media screen and (min-width: $md) {
          text-align: left;
          font-size: 18px;
          line-height: 28px;
        }
      }
      h2{
        font-size: 48px;
        @media screen and (min-width: $md) {
          text-align: left;
        }
        div{
          color: #FF6767;
          font-size: 65px;
        }
      }
      .description{
        max-width: 500px;
        text-align: center;
        margin: auto;
        @media screen and (min-width: $md) {
          margin: inherit;
        }
      }
      .button{
        margin: 20px auto 0px auto;
        @media screen and (min-width: $md) {
          margin: 20px 0px 0px 0px;
          width: fit-content;
          display: inline-block;
          position: initial;
          float: right;
        }
      }
    }
  }
  .background-container{
    width: 100%;
    img{
      position: absolute;
      z-index: 1;
    }
    div{
      top: 0px;
      position: absolute;
      height: 100%;
    }
    .background-left{
      width: 70%;
      left: 0px;
      background-color: #FBEBEB;
    }
    .background-right{
      width: 30%;
      right: 0px;
      background-color: #ffffff;
    }
  }
}




.page-home{
  .page-content{
    padding-bottom: 0px;
  }
  .first_row{
    position: relative;
    @media screen and (min-width: $lg) {
      min-height: calc(33vw + #{$desktop_header_height});

    }
    .MuiGrid-container {
      // flex-direction: row-reverse;
      z-index: 2;
      position: relative;

      @media screen and (min-width: $md) {
          height: auto;
          align-items: center;
          position: absolute;
          top: calc( 50% - 40px );
          transform: translateY(-50%);
      }
    }
  }
  .second-row{
    &.MuiGrid-container{
      flex-direction: row-reverse;
    }
  }
}



body .page-content{
  background-color: #ffffff;
}


.image-deco-first{
  width: 100%;
  max-height: 900px;
  height: 100%;
  z-index: 1;
  position: relative;

  img{
    min-height: 100%;
    min-width: 100%;
    width: 100%;
    @media screen and (min-width: $md) {
      width: auto;
    }
  }
}



/** MENU DESKTOP **/
.desktop-menu{
  position: fixed;
  top: 72px;
  left: 0px;
  height: calc( 100% - 72px );
  width: 100%;
  z-index: 10;
  left: -100%;
  //transition: 0.3s ease-in-out;
  transition-delay: 0.3s;
  .menu-content{
    position: absolute;
    top: 0px;
    width: 264px;
    background-color: #FFF;
    height: 100%;
    z-index: 11;
    left: -100%;
    transition: 0.3s ease-in-out;
    padding: 15px 30px 30px 30px;
    hr{
      opacity: 0.15;
      border-top: solid 1px #000032;
      border-bottom: none;
    }
    ul{
      list-style: none;
      padding: 0px;
      li{
        padding:10px 0px;
        a{
          font-size: 16px;
          color:#000033;
          text-decoration: none;
          text-transform: uppercase;
        }
      }
    }
  }
  .background-overlay{
    transition: opacity 0.3s ease-in-out;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0;
  }
}
body.menu-is-open{
  .desktop-menu{
    left: 0%;
    transition-delay: 0s;
    .menu-content{
      left: 0%
    }
    .background-overlay{
      opacity: 0.4;
    }
  }
}



/***** SECTIONS TIPS *****/
.section-tips{
  padding-top: 90px;
  background-color: #ffffff;
  @media screen and (min-width: $md) {
    position: relative;
    margin-top: -211px;
    background-color: transparent;
  }
  .highlight-link-container{
    margin-bottom: 50px;
    @media screen and (min-width: $md) {
      text-align: left;
      margin-bottom: 0px;
    }
    a{
      color: #FF6767;
      font-size: 17px;
      svg {
        top: 3px;
        fill: #FF6767;
      }
    }
  }

  .secondary-container{
    padding-bottom: 40px;
    position: relative;

    @media screen and (min-width: $md) {
      padding-left: 100px;
      padding-top: 5vw;
      background-color: #FFF4F4;
    }
    @media screen and (min-width: 1388px) {
      background-color: transparent;
    }

    .MuiGrid-container{
      @media screen and (min-width: $md) {
        align-items: center;
      }
      h2{
        font-size: 61px;
        line-height: 61px;
        margin: 0px;
        @media screen and (min-width: $md) {
          font-size: 82px;
          line-height: 82px;
          text-align: left;
        }
        span{
          color: #FF6767;
        }
      }
    }

  }
  .background-container{
    width: auto;
    height: auto;
    left: 50%;
    transform: translateX(-50%);
    background-color: transparent;
    @media screen and (min-width: $md) {
      width: 100%;
    }
  }
}

/*** SECTION FAQ ***/
.section-faq{
  background-color: #FFFFFF;
  padding-top: 30px;
  h1{
    text-align: center;
    font-size: 61px;
    line-height: 61px;
    span{
      color: #FF6767;
    }
  }
  @media screen and (min-width: $md) {
    position: relative;
  }
  .highlight-link-container{
    margin-bottom: 50px;
    @media screen and (min-width: $md) {
      text-align: left;
      margin-bottom: 0px;
    }
    a{
      color: #FF6767;
      font-size: 17px;
      svg {
        top: 3px;
        fill: #FF6767;
      }
    }
  }



  .secondary-container{
    padding-bottom: 40px;

    @media screen and (min-width: $md) {
      padding-left: 100px;
      padding-top: 130px;
      padding-bottom: 130px;
    }
    .MuiGrid-container{
      @media screen and (min-width: $md) {
        align-items: center;
      }
      h2{
        font-size: 61px;
        line-height: 61px;
        margin: 0px;
        @media screen and (min-width: $md) {
          font-size: 82px;
          line-height: 82px;
          text-align: left;
        }
        div{
          color: #FF6767;
        }
      }
    }

  }

}