.custom-slick-container.tips-component{
  //: 100vw;
  position: relative;
  //padding: 0px 25px;
  @media screen and (min-width: $md) {
    margin: -16px 0px 0px -16px;
  }
  .custom-slider{
    max-width: 100vw;
    width: auto;
    margin: auto;
    @media screen and (min-width: $md) {
      max-width: 634px;
      height: 412px;
    }
    .custom-slick-next{
      right: 13px;
    }
    .custom-slick-prev{
      left: 13px;
    }
    .slick-slide{
      text-align: left;
      div:first-child{
        FONT-SIZE: 0px;
        margin: auto;
        /* @media screen and (min-width: $md) {
           margin: 16px;
         }*/
      }
      @media screen and (min-width: $md) {
        margin: 0px 0px 0px 0px;
      }
      .box-container{
        height: 100%;
        @media screen and (min-width: $md) {
          overflow: hidden;
        }
        .box{
          border-radius: 5px;
          background-color: #fff;
          padding: 33px;
          font-size: 16px;
          color:#000000;
          max-width: 286px;
          /*width: 286px;*/
          min-height: 174px;
          box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, .07);
          margin: auto;
          height: 100%;
          @media screen and (min-width: $md) {
            height: 160px;
            overflow: hidden;
            margin: 16px !important;
          }
          .title{
            font-weight: bold;
            color:#FF6767;
            text-align: center;
            margin-top: 16px;
            font-size: 18px;
            line-height: 28px;
            @media screen and (min-width: $md) {
              line-height: 20px;
            }
            @media screen and (min-width: $lg) {
              line-height: 28px;
            }
          }
          .picto{
            img{
              margin: auto;
              height: 49px;
            }
          }
        }
      }
    }
    .custom-slick-prev{
      @media screen and (min-width: $md) {
        left: -45px;
      }
    }
    .custom-slick-next{
      @media screen and (min-width: $md) {
        right: 33px;
      }
    }
  }
}