footer{
  padding-bottom: 80px;
  @media screen and (min-width: $md) {
    padding-bottom: 0px;
  }
  &.primary-container .background-container{
    display: none;
    @media screen and (min-width: $md) {
      display: block;
      width: auto;
      height: 115%;
      path {
        fill: #000033;

      }
    }
  }
  .footer{
    .copyright{
      text-align: center;
      font-size: 14px;
      opacity: 0.4;
    }
    .footer-group-top{
      @media screen and (min-width: $md) {
        display: inline-block;
        display: flex;
        justify-content: center;
      }
      .footer-top{
        border-bottom: solid 1px rgba( 0,0,51,0.15);
        padding: 10px 0px 10px 0px;
        @media screen and (min-width: $md) {
          border-bottom: none;
          border-right: solid 1px rgba( 0,0,51,0.15);
          display: inline-block;
        }
        .picto-list{
          .picto-item{
            padding: 0px 10px;
          }
        }
      }
      .footer-mid{
        @media screen and (min-width: $md) {
          display: inline-block;
        }
      }

    }
    .picto-list{
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (min-width: $md) {
        display: inline-flex;
      }
      .picto-item{
        display: inherit;
        //flex: 1;
        width: 111px;
        img{
          width: 100%;
        }
      }
      &.picto-shipping-list{
        padding: 5px 0px;
        .picto-item{
          @media screen and (min-width: $md) {
            width: auto;
            margin: 0px 5px;
          }
          img{
            max-height: 45px;
            max-width: 35px;
          }
        }
      }
      &.picto-paiement-list{
        padding: 10px 0px;
        margin: 10px 0px;
        img{
          max-height: 12px;
        }
      }
      &.picto-paiement-list-2{
        margin: 10px 0px;
        @media screen and (min-width: $md) {
          position: relative;
          top: 7px;
          left: 17px;
        }
        img{
          max-height: 26px;
        }
      }
    }

    .footer-bottom{
      margin-top: 20px;
      margin-bottom: 30px;
      #footer-menu{
        display: flex;
        list-style: none;
        justify-content: space-between;
        flex-flow: row wrap;
        margin: 0px auto;
        padding:0px;
        width: 222px;
        @media screen and (min-width: $md) {
          width: auto;
          justify-content: center;
        }
        li{
          padding: 10px 5px ;
          @media screen and (min-width: $md) {
            padding: 10px 20px ;
          }
          a{
            text-decoration: none;
            font-size: 14px;
            color: #000033;
            white-space: nowrap;

          }
        }
      }
    }
  }


}